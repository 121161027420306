<template>
  <b-row>
    <b-col
      cols="12"
      class="mx-auto"
    >
      <b-card class="mb-2 mobile-ux">
        <b-card-text class="px-5 py-2">
          <h4 class="font-weight-bolder mb-2 text-primary"><feather-icon
            icon="GlobeIcon"
            size="16"
            class="ch-title"
          /> NETWORK DOMAIN</h4>
          <div v-if="setting.network_type==='domain'">
            <p><strong>Domain:</strong> {{ setting.domain }}</p>
            <p><strong>Dns type:</strong> {{ dns_type }}</p>
            <p><strong>Datacenter location:</strong> {{ datacenter_location }}</p>
          </div>
          <div v-else-if="setting.network_type==='subdomain'">
            <p><strong>TrafficManager subdomain:</strong> {{ setting.subdomain }}</p>
            <p><strong>Datacenter location:</strong> {{ datacenter_location }} </p>
          </div>

          <div
            v-if="show_tm_dns_option && user.user_status<3 && !nsError"
            class="nsAlertbox"
          >
            <b-alert
              show
              variant="warning"
            >
              <div
                style=""
                class="text-primary div-mobile-ux"
              >
                <p><strong>What do you need to do?</strong></p>
                <p>
                  <feather-icon
                    icon="AlertTriangleIcon"
                    size="12"
                    class="ch-title"
                  />
                  Change the name servers (NS) in your domain's Registrar settings to:</p>
                <ul>
                  <li style="line-height:35px;">
                    <span
                      class="ns1_class"
                    >{{ ns1 }}</span>
                    <b-button
                      class="mobile-ux-btn"
                      type="button"
                      size="sm"
                      @click="copyNs1"
                    >Copy
                    </b-button>
                  </li>
                  <li
                    v-if="ns2"
                    style="line-height:35px"
                  >
                    <span
                      class="ns2_class"
                    >
                      {{ ns2 }}
                    </span>
                    <b-button
                      class="mobile-ux-btn"
                      type="button"
                      size="sm"
                      @click="copyNs2"
                    >Copy
                    </b-button>
                  </li>
                </ul>
                <div class="checkDNSMobile-ux">
                  <p>
                    <feather-icon
                      icon="AlertTriangleIcon"
                      size="12"
                      class="ch-title"
                    />
                    DNS not set
                  </p>
                  <b-button
                    variant="primary"
                    :disabled="sending"
                    size="sm"
                    @click="check_dns"
                  >
                    <span v-if="sending">
                      <b-spinner
                        small
                        type="grow"
                      />
                      Checking DNS...
                    </span>
                    <span v-else>Check DNS</span>
                  </b-button>
                </div>
              </div>
            </b-alert>
          </div>

          <div
            v-if="show_tm_dns_option && user.user_status>2 && !nsError"
            class="nsAlertbox"
          >
            <b-alert
              show
              variant="success"
            >
              <div
                style="padding:25px"
                class="text-primary"
              >
                <p><strong>Your nameservers:</strong></p>
                <ul>
                  <li style="line-height:35px;">
                    <span
                      class="ns1_class"
                    >{{ ns1 }}</span>
                    <b-button
                      style="margin-left:40px"
                      type="button"
                      size="sm"
                      @click="copyNs1"
                    >Copy
                    </b-button>
                  </li>
                  <li
                    v-if="ns2"
                    style="line-height:35px"
                  >
                    <span
                      class="ns2_class"
                    >
                      {{ ns2 }}
                    </span>
                    <b-button
                      style="margin-left:40px"
                      type="button"
                      size="sm"
                      @click="copyNs2"
                    >Copy
                    </b-button>
                  </li>
                </ul>
              </div>
            </b-alert>
          </div>

          <div
            v-if="show_tm_dns_option && nsError"
            class="nsAlertbox"
          >
            <b-alert
              show
              variant="warning"
            >
              <div
                style="padding:25px"
                class="text-primary"
              >
                <p><feather-icon
                  icon="AlertTriangleIcon"
                  size="12"
                  class="ch-title mr-1"
                />{{ nsError }}</p>
              </div>
            </b-alert>
          </div>

          <div
            v-if="show_own_dns_option && user.user_status<3"
            class="nsAlertbox"
          >
            <b-alert
              show
              variant="warning"
            >
              <div
                style="padding:25px"
                class="text-primary"
              >
                <p>
                  <feather-icon
                    icon="AlertTriangleIcon"
                    size="12"
                    class="ch-title"
                  />
                  For these sub-domains, please add the following CNAME aliases in your domain's Registrar DNS settings:</p>
                <ul>
                  <li><b>{{ setting.sub_offers }}</b> Domain name (alias value): {{ cmydomain }}.trafficman.io</li>
                  <li><b>{{ setting.sub_smart }}</b> Domain name (alias value): {{ cmydomain }}.trafficman.io</li>
                  <li><b>{{ setting.sub_admin }}</b> Domain name (alias value): {{ cmydomain }}.trafficman.io</li>
                  <li><b>{{ setting.sub_banners }}</b> Domain name (alias value): {{ cmydomain }}.trafficman.io</li>
                  <li><b>{{ setting.sub_static }}</b> Domain name (alias value): {{ cmydomain }}.trafficman.io</li>
                  <li><b>{{ setting.sub_postback }}</b> Domain name (alias value): {{ cmydomain }}.trafficman.io</li>
                </ul>
                <p>
                  <feather-icon
                    icon="AlertTriangleIcon"
                    size="12"
                    class="ch-title"
                  />
                  DNS not set
                </p>
              </div>
            </b-alert>
          </div>
          <div
            v-if="show_own_dns_option && user.user_status>2"
            class="nsAlertbox"
          >
            <b-alert
              show
              variant="success"
            >
              <div style="padding:25px">
                <p>
                  <feather-icon
                    icon="AlertTriangleIcon"
                    size="12"
                    class="ch-title"
                  />
                  Your CNAME aliases:</p>
                <ul>
                  <li><b>{{ setting.sub_offers }}</b> Domain name (alias value): <b>{{ cmydomain }}.trafficman.io</b></li>
                  <li><b>{{ setting.sub_smart }}</b> Domain name (alias value): <b>{{ cmydomain }}.trafficman.io</b></li>
                  <li><b>{{ setting.sub_admin }}</b> Domain name (alias value): <b>{{ cmydomain }}.trafficman.io</b></li>
                  <li><b>{{ setting.sub_banners }}</b> Domain name (alias value): <b>{{ cmydomain }}.trafficman.io</b></li>
                  <li><b>{{ setting.sub_static }}</b> Domain name (alias value): <b>{{ cmydomain }}.trafficman.io</b></li>
                  <li><b>{{ setting.sub_postback }}</b> Domain name (alias value): <b>{{ cmydomain }}.trafficman.io</b></li>
                </ul>
              </div>
            </b-alert>
          </div>
          <div
            v-if="statusDNS"
            class="nsAlertbox"
          >
            <b-alert
              show
              :variant="statusDNS == 'DNS is set.' ? 'success' : 'danger'"
            >
              <div
                style="padding:25px"
                :class="statusDNS == 'DNS is set.' ? 'text-success' : 'text-danger'"
              >
                <p><feather-icon
                  icon="AlertTriangleIcon"
                  size="12"
                  class="ch-title mr-1"
                />{{ statusDNS }}</p>
              </div>
            </b-alert>
          </div>
          <div
            v-else
            class="my-1 d-flex justify-content-center"
          >
            <b-spinner
              type="grow"
              variant="primary"
              label="Loading..."
              small
            />
          </div>
          <b-button
            class="px-2"
            size="sm"
            variant="primary"
            :disabled="sending"
            @click="check_dns"
          >
            <span v-if="sending">
              <b-spinner
                small
                type="grow"
              />
              Checking DNS...
            </span>
            <span v-else>Check DNS</span>
          </b-button>

          <hr>
          <h4 class="font-weight-bolder mb-2 text-primary"><feather-icon
            icon="GlobeIcon"
            size="16"
            class="ch-title"
          /> SUBDOMAINS</h4>
          <div v-if="setting.network_type === 'domain'">
            <p>Admin / Control Panel: <strong>{{ setting.sub_admin }}.{{ setting.domain }}</strong></p>
            <p>Dashboard For Affiliates: <strong>{{ setting.sub_admin }}.{{ setting.domain }}</strong></p>
            <p>Offers Tracking Domain: <strong>{{ setting.sub_offers }}.{{ setting.domain }}</strong></p>
            <p>Smart Tracking Domain: <strong>{{ setting.sub_smart }}.{{ setting.domain }}</strong></p>
            <p>Banners: <strong>{{ setting.sub_banners }}.{{ setting.domain }}</strong></p>
            <p>Static: <strong>{{ setting.sub_static }}.{{ setting.domain }}</strong></p>
            <p class="mb-2">
              Postback: <strong>{{ setting.sub_postback }}.{{ setting.domain }}</strong>
            </p>
          </div>
          <div v-else>
            <p>Admin / Control Panel: <strong>{{ setting.subdomain }}.trafficmanager.com</strong></p>
            <p>Dashboard For Affiliates: <strong>{{ setting.subdomain }}.trafficmanager.com</strong></p>
            <p>Offers Tracking Domain: <strong>offers.{{ setting.subdomain }}.trafficmanager.io</strong></p>
            <p>Smart Tracking Domain: <strong>smart.{{ setting.subdomain }}.trafficmanager.io</strong></p>
            <p>Banners: <strong>banners-{{ setting.subdomain }}.trafficmanager.io</strong></p>
            <p>Static: <strong>static-{{ setting.subdomain }}.trafficmanager.io</strong></p>
            <p class="mb-2">
              Postback: <strong>postback.{{ setting.subdomain }}.trafficmanager.io</strong>
            </p>
          </div>

          <div
            v-if="isNewSubDomain"
            class="warning-section p-1 text-center"
          >
            <p>Your TrafficManager platform configuration was done successfully. Please contact our support team in order to receive your access credentials via our ticketing system.</p>
            <p>
              <a href="/tickets/new-ticket">
                <span class="warning-link">CLICK HERE TO CONTACT OUR TEAM.</span>
              </a>
            </p>
          </div>
          <div
            v-else-if="user.user_status<4"
            class="warning-section p-1 text-center"
          >
            Please note that is takes 24-72 hours for the domain setup to propagate through the Internet and to be updated in our systems.
          </div>

          <hr>
          <h4 class="font-weight-bolder mb-2 text-primary"><feather-icon
            icon="PackageIcon"
            size="16"
            class="ch-title"
          />
            Optional Features
            <router-link
              v-if="user.user_status >= 4"
              to="/optional-features"
            >
              <b-button
                class="px-1 ml-1"
                size="sm"
                variant="outline-primary"
              >
                All Optional Features
              </b-button>
            </router-link></h4>
          <div class="text-center">
            <b-col
              md="12"
            >
              <b-card
                v-for="item in list"
                :key="item.id"
                header=""
                :footer="item.footer"
                class="text-center border border-secondary"
                footer-class="text-danger"
              >
                <b-card-text class="mx-md-5">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <div v-html="item.description" />
                  <br><br>
                  <b-button
                    v-if="item.disableButton"
                    variant="danger"
                    :disabled="sendingOpt[item.id]"
                    @click="toggleEnableDisabled(item)"
                  >
                    <span v-if="sendingOpt[item.id]">
                      <b-spinner
                        small
                        type="grow"
                      />
                      disabling...
                    </span>
                    <span
                      v-else
                      class="d-flex align-items-center"
                    >
                      <feather-icon
                        icon="StopCircleIcon"
                        size="18"
                        style="margin-right: 5px;"
                      /> Disable
                    </span>
                  </b-button>
                  <b-button
                    v-else
                    variant="primary"
                    :disabled="sendingOpt[item.id]"
                    @click="toggleEnableDisabled(item)"
                  >
                    <span v-if="sendingOpt[item.id]">
                      <b-spinner
                        small
                        type="grow"
                      />
                      enabling...
                    </span>
                    <span
                      v-else
                      class="d-flex align-items-center"
                    >
                      <feather-icon
                        icon="PlayCircleIcon"
                        size="18"
                        style="margin-right: 5px;"
                      /> Enable
                    </span>
                  </b-button>
                </b-card-text>
              </b-card>
            </b-col>
          </div>
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BAlert, BButton, BSpinner,
} from 'bootstrap-vue'
import axios from '@/libs/axios'
import VueCookies from 'vue-cookies'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BAlert,
    BButton,
    BSpinner,
  },
  props: {
    setting: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      datacenter_location: '',
      dns_type: '',
      show_tm_dns_option: false,
      show_own_dns_option: false,
      ns1: '',
      ns2: '',
      nsError: '',
      sending: false,
      cmydomain: '',
      isNewSubDomain: '',
      statusDNS: null,

      enabled: [],
      optional_features: [],
      list: [],
      sendingOpt: {
        1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false,
      },
    }
  },
  computed: {
    isPhone: {
      get() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) return true
        return false
      },
    },

    userData() {
      return this.$store.state.auth.userData
    },

    featureData() {
      return this.$store.state.optional.featureData
    },
  },
  created() {
    this.fetchOptionalFeatures()
  },
  mounted() {
    this.initData()
  },
  methods: {
    async fetchOptionalFeatures() {
      await this.$store.dispatch('optional/fetchOptionalFeatures')

      this.enabled = this.featureData.enabled
      this.optional_features = this.featureData.optional_features.filter(feature => feature.id === 1 || feature.id === 4)

      this.optional_features.forEach(feature => {
        const obj = feature
        const id = Number(feature.id)

        obj.footer = ''
        if (id === 1) {
          obj.footer = 'If you disable the option, all the existing data will be permanently deleted.'
        }

        obj.disableButton = false
        obj.enableButton = true
        if (feature.hasOptionalFeature) {
          obj.disableButton = true
          obj.enableButton = false
        }

        this.list.push(obj)
      })
    },

    async toggleEnableDisabled(item) {
      if (this.user.user_status < 4) {
        this.notify({
          text: 'Cannot add Optional Features before entering trial period !',
          variant: 'danger',
        })
        return
      }
      if (this.user.user_status === 6 || this.user.user_status === 7) {
        this.notify({
          text: 'Cannot add Optional Features to a cancelled or expired account. Please contact us.',
          variant: 'danger',
        })
      } else {
        this.sendingOpt[item.id] = true
        const payload = {
          feature_id: item.id,
        }

        const res = await this.$store.dispatch('optional/toggleOptionalFeatures', payload)
        this.sendingOpt[item.id] = false
        if (res.isRedirectToInvoice) {
          const msg = res.message
          localStorage.setItem('invoice_message', msg)
          this.$router.push(`/invoice/current/${item.invoice_id}`)
        } else {
          this.$router.go(0)
        }
      }
    },

    async initData() {
      const response2 = axios.get('/api/settings/user/check_tm_dns')
      await response2.then(result => result).then(result => {
        if (result.status === 200) {
          const isOk = result.data
          if (isOk === false) this.statusDNS = 'DNS not set.'
          else this.statusDNS = 'DNS is set.'
        }
      })

      this.dns_type = 'TrafficManager DNS'
      if (this.setting.dns_type === 'owndns') this.dns_type = 'Your own DNS'
      if (this.setting.datacenter_location === 1) this.datacenter_location = 'Europe'
      if (this.setting.datacenter_location === 2) this.datacenter_location = 'America (US West Coast)'
      if (this.setting.datacenter_location === 3) this.datacenter_location = 'America (US East Coast)'
      if (this.setting.datacenter_location === 4) this.datacenter_location = 'Asia'

      if (this.setting.network_type === 'domain' && this.setting.dns_type === 'tmdns') this.show_tm_dns_option = true
      else if (this.setting.network_type === 'domain' && this.setting.dns_type === 'owndns') this.show_own_dns_option = true

      const uri = '/api/settings/user/name_servers'
      const response = axios.get(uri)
      const resData = await response.then(result => result)// eslint-disable-line

      if (resData?.status === 200) {
        const { data } = resData
        this.ns1 = data[0] // eslint-disable-line
        if (!(typeof data[1] === 'undefined')) this.ns2 = data[1] // eslint-disable-line

        if (this.ns1 && this.ns2 === '') {
          this.nsError = 'Nameservers are not available at this moment.'
          this.ns1 = ''
        }
      }

      this.cmydomain = this.setting.domain.replace('.', '-')
      this.isNewSubDomain = VueCookies.get('isNewSubDomain')
    },
    async check_dns() {
      this.sending = true
      const uri = '/api/settings/user/check_tm_dns'
      const response = axios.get(uri)
      const resData = await response.then(result => result)// eslint-disable-line

      if (resData.status === 200) {
        const isOk = resData.data
        if (isOk === false) {
          this.notify({
            text: 'DNS not set.',
            variant: 'danger',
          })
          this.statusDNS = 'DNS not set.'
        } else {
          this.notify({
            text: 'DNS is set.',
            variant: 'success',
          })
          this.statusDNS = 'DNS is set.'
        }
        this.sending = false
      }
    },
    copyNs1() {
      const range = this.getRange('ns1_class')
      this.copySelectedText(range)
      this.notify({
        text: `${range.startContainer.innerText} is copied.`,
        variant: 'success',
      })
    },
    copyNs2() {
      const range = this.getRange('ns2_class')
      this.copySelectedText(range)
      this.notify({
        text: `${range.startContainer.innerText} is copied.`,
        variant: 'success',
      })
    },
    copySelectedText(range) {
      const sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)
      document.execCommand('copy')
    },
    getRange(classname) {
      const el = document.getElementsByClassName(classname)[0]
      const range = document.createRange()
      range.selectNodeContents(el)
      return range
    },
  },

}
</script>
<style lang="scss">
  [dir="ltr"] .ml-auto, [dir="ltr"] .mx-auto{
    margin-left:auto !important;
  }
</style>

<style scoped>
.ch-title {
  position: relative;
  top: -2px;
}

.warning-section {
  background: linear-gradient(90deg, rgba(255,169,121,.992), rgba(255,98,49,.992));
  color: white;
  text-shadow: 1px 1px black;
  border-radius: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  border: 1px dotted black;
}

.warning-link {
  font-size: 18px;
  background: -webkit-linear-gradient(white, black, white);
  text-shadow: none;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.warning-link:hover {
  color: white;
  text-shadow: 1px 1px black;
}

.nsAlertbox{
  padding:10px
}
.div-mobile-ux{
  padding:25px;
  padding-bottom: 50px;
}
.mobile-ux-btn{
  margin-left:40px
}

.checkDNSMobile-ux p,.checkDNSMobile-ux button{
  float: inline-start;
}
.checkDNSMobile-ux button{
  margin-left: 10px;
}

/* iphone 12/13 pro max - android pro max*/
@media only screen and (max-width: 431px)  {
  .mobile-ux{
    padding:0px !important;
  }
  .div-mobile-ux{
    padding: 1px !important;
  }
  .div-mobile-ux ul{
    padding: 15px !important;
  }

  [dir="ltr"] .px-5{
    padding:30px 10px 30px 10px !important;
  }

  [dir="ltr"] .ml-auto {
    margin-left:auto !important;
  }

  [dir] .alert{
    padding:10px !important
  }
  .mobile-ux-btn{
    margin-left:10px
  }
  .div-mobile-ux ul li{
    text-align: center;
    list-style-type: none;
  }
  .checkDNSMobile-ux{
    text-align: center;
  }
  .checkDNSMobile-ux p,.checkDNSMobile-ux button{
    margin-top: 0px;
    float: none !important;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 415px)  {

}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 395px)  {

}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 376px)  {

}

/* smaller phone */
@media only screen and (max-width: 365px)  {

}
</style>
