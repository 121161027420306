<template>
  <config-detail
    v-if="isDetail"
    :setting="setting"
    :user="user"
  />
  <config-form v-else />
</template>

<script>

import ConfigDetail from './configuration/ConfigDetail.vue'
import ConfigForm from './configuration/ConfigForm.vue'
import axios from '@/libs/axios' // eslint-disable-line

export default {
  components: {
    ConfigDetail,
    ConfigForm,
  },

  data() {
    return {
      isDetail: false,
      setting: {},
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
  },

  mounted() {
    this.getUserSetting()
  },

  methods: {

    async getUserSetting() {
      const { uid } = this.user
      const uri = `/api/settings/${uid}`
      const response = axios.get(uri)
      const resData = await response.then(result => result)

      if (resData.status === 200) {
        this.setting = resData.data

        // check if user needs to configure
        if (this.setting.setstep1 === 0) this.isDetail = false
        else this.isDetail = true
      }
    },
  },
}
</script>
