<template>
  <div>
    <br>
    <validation-observer ref="rulesAccesTab">
      <h6 class="font-weight-bolder mb-1 text-danger"><feather-icon
        icon="LinkIcon"
        size="16"
        class="ch-title"
      /> {{ website.title }}
      </h6>
      <validation-provider
        #default="{ errors }"
        name="subdomain"
        rules="required"
      >
        <b-input-group>
          <template #append>
            <b-input-group-text
              style="background-color:lightgrey; padding-left: 5px;"
              class="text-black-50"
            >
              {{ addDotDomain }}
            </b-input-group-text>
          </template>

          <b-form-input
            id="subdomain"
            v-model="website.subdomain"
            :disabled="website.useTMdomain"
            style="padding-right: 1px; text-align: right;"
            @focus="$event.target.select()"
          />

        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
      <p style="color:#8c8c8c; font-size:small;text-align: justify;">
        {{ website.description }}
      </p>
    </validation-observer>
  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupText, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'

export default {
  components: {
    BInputGroup,
    BInputGroupText,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    website: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {}
  },
  computed: {
    addDotDomain() {
      if (!this.website.domain.startsWith('.')) return `.${this.website.domain}`
      return this.website.domain
    },
  },
  methods: {
    async validate() {
      const isPassed = await this.$refs.rulesAccesTab.validate().then(success => success)
      this.website.validated = isPassed
    },
  },
}
</script>
<style lang="scss" scoped>

// .input-domain {
//     direction: rtl; // or dir attribute is rtl in input tag
//     text-align: start;
// }

.ch-title {
  position: relative;
  top: -2px;
}

</style>
