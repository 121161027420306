<template>
  <div>
    <b-modal
      id="video-config"
      title="How to setup your TrafficManager platform - Video Guide"
      centered
      hide-footer
      size="lg"
      @ok="closeVideoConfig"
      @cancel="closeVideoConfig"
      @close="closeVideoConfig"
      @hide="closeVideoConfig"
    >
      <iframe
        class="w-100 border border-secondary rounded mb-2"
        style="height: 500px;"
        src="https://www.youtube.com/embed/mrRfiAPNBxI?showinfo=0&modestbranding=1"
        frameborder="0"
      />
    </b-modal>
    <!-- Data center location -->
    <b-row class="mobileContainer">
      <b-col
        cols="9"
        class="mx-auto mobileContainer"
      ><validation-observer ref="rules">
         <b-card
           class="mb-2 mobileContainer"
         >
           <b-card-text
             class="px-5 py-2 mbwindow mobileWidth"
             style="padding-left: auto !important; padding-right: auto !important;"
           >
             <b-button
               class="font-weight-bolder btn-success"
               size="sm"
               @click="$bvModal.show('video-config')"
             >
               How to setup your TrafficManager platform - Video Guide
             </b-button>
             <hr>
             <h4 class="font-weight-bolder mb-2 text-primary">
               <feather-icon
                 icon="GlobeIcon"
                 size="16"
                 class="ch-title"
               /> DATA CENTER LOCATION
             </h4>
             <b-row class="mobileWidth">
               <b-col
                 cols="6"
                 class="mobileWidth"
               >
                 <p class="mb-0">
                   Where is most of your traffic located? <br>(if you're unsure, select 'Europe')
                 </p>
                 <br>
                 <b-form-select
                   v-model="dataCenter"
                   :options="dataCenterOption"
                   style="width:280px"
                 />
               </b-col>
               <b-col
                 cols="6"
                 class="mobileWidth"
               >
                 <b-card
                   no-body
                   class="border-primary passmargin"
                   style="padding:15px"
                 >
                   <b-card-header
                     class="d-flex justify-content-between align-items-center"
                     style="padding:5px"
                   >
                     <b-badge
                       variant="light-primary cfs-16"
                     >
                       <feather-icon
                         icon="KeyIcon"
                         size="16"
                         class="ch-title"
                       /> Admin Password
                     </b-badge>
                   </b-card-header>
                   <b-card-body>
                     <validation-provider
                       #default="{ errors }"
                       name="password"
                       rules="required"
                     >
                       <b-form-input
                         id="password"
                         ref="password"
                         v-model="password"
                         placeholder="Password"
                         type="password"
                       />
                       <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                     <b-form-group
                       label="Confirm Password"
                       label-for="confirm"
                       style="margin-top:8px"
                     >
                       <validation-provider
                         #default="{ errors }"
                         name="password_confirmation"
                         rules="required|confirmed:password"
                       >
                         <b-form-input
                           id="password_confirmation"
                           v-model="password_confirmation"
                           placeholder="Confirm Password"
                           type="password"
                         />
                         <small class="text-danger">{{ errors[0] }}</small>
                       </validation-provider>
                     </b-form-group>
                   </b-card-body>
                 </b-card>
               </b-col>
             </b-row>
             <hr>
             <!-- Network domain -->
             <h4 class="font-weight-bolder mb-2 text-primary"><feather-icon
               icon="GlobeIcon"
               size="16"
               class="ch-title"
             /> NETWORK DOMAIN</h4>
             <b-row>
               <b-col
                 cols="6"
                 class="mobileWidth"
               >
                 <div>
                   <b-form-group
                     label-for="radio-group-1"
                     label="You have two choices:"
                   >
                     <b-form-radio-group
                       id="radio-group-1"
                       v-model="network_type"
                       :options="network_type_option"
                       name="network_domain_radio"
                       @change="changeNetworkType"
                     />
                   </b-form-group>
                 </div>
               </b-col>
               <b-col
                 cols="6"
                 class="mobileWidth"
               >
                 <div v-if="network_type===1">
                   <b-form-group
                     label="Enter your own domain *"
                     label-for="domain"
                   >
                     <validation-provider
                       #default="{ errors }"
                       name="domain"
                       rules="required"
                     >
                       <b-form-input
                         id="domain"
                         v-model="domain"
                         placeholder="mydomain.com"
                         @change="changeDomain"
                         @keyup="changeDomain"
                         @focus="$event.target.select()"
                       />
                       <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                   </b-form-group>
                   <b-form-group
                     label-for="radio-group-2"
                     label="You have two DNS choices:"
                   >
                     <b-form-radio-group
                       id="radio-group-2"
                       v-model="dns_type"
                       :options="dns_type_option"
                       name="dns_radio"
                     />
                   </b-form-group>
                 </div>
                 <div v-else-if="network_type===2">
                   <b-form-group
                     label="Choose a TrafficManager subdomain *"
                     label-for="subdomain"
                   ><validation-provider
                     #default="{ errors }"
                     name="subdomain"
                     rules="required"
                   >
                     <b-input-group>
                       <template #append>
                         <b-input-group-text style="background-color:#cccccc; padding-left: 5px;">
                           .trafficmanager.com
                         </b-input-group-text>
                       </template>
                       <b-form-input
                         id="subdomain"
                         v-model="subdomain"
                         style="text-align: right; padding-right: 1px;"
                         @change="assignTMDomain"
                         @keyup="assignTMDomain"
                         @focus="$event.target.select()"
                       />
                     </b-input-group>
                     <small class="text-danger">{{ errors[0] }}</small>
                   </validation-provider>
                   </b-form-group>
                 </div>
               </b-col>
             </b-row>
             <br>
             <hr>
             <h4 class="font-weight-bolder mb-2 text-primary"><feather-icon
               icon="GlobeIcon"
               size="16"
               class="ch-title"
             /> SUBDOMAIN DOMAIN</h4>
             <p style="text-align:justify">
               NOTE: The subdomains below are required for your TrafficManager system to work. If you don't change them, the default values will be used (dashboard, offers, smart, banners, static, postback). The subdomain names have to be different from each other.
             </p>

             <b-row>
               <b-col
                 cols="6"
                 class="mobileWidth"
               >
                 <config-subdomain
                   ref="admin_subdomain"
                   :website="admin"
                 />
               </b-col>
               <b-col
                 cols="6"
                 class="mobileWidth"
               >
                 <config-subdomain
                   ref="offers_subdomain"
                   :website="offers"
                 />
               </b-col>
               <b-col
                 cols="6"
                 class="mobileWidth"
               >
                 <config-subdomain
                   ref="smart_subdomain"
                   :website="smart"
                 />
               </b-col>
               <b-col
                 cols="6"
                 class="mobileWidth"
               >
                 <config-subdomain
                   ref="banners_subdomain"
                   :website="banners"
                 />
               </b-col>
               <b-col
                 cols="6"
                 class="mobileWidth"
               >
                 <config-subdomain
                   ref="static_subdomain"
                   :website="static_domain"
                 />
               </b-col>
               <b-col
                 cols="6"
                 class="mobileWidth"
               >
                 <config-subdomain
                   ref="website_subdomain"
                   :website="postback"
                 />
               </b-col>
               <b-col
                 cols="6"
                 class="mobileWidth"
               >
                 <!--                 <config-short-subdomain :website="short" />-->
               </b-col>
             </b-row>
             <b-row class="text-center">

               <b-col
                 cols="12"
                 class="text-center"
               >
                 <b-button
                   variant="primary"
                   :disabled="sending"
                   @click="save"
                 >
                   <span v-if="sending">
                     <b-spinner
                       small
                       type="grow"
                     />
                     Saving...
                   </span>
                   <span v-else>Save</span>
                 </b-button>
               </b-col>

             </b-row>

           </b-card-text>
         </b-card>
       </validation-observer>
        <b-modal
          id="save-config"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          title="Please review your settings."
        >
          <div class="text-center">
            <table
              class="settingTable"
              cellpadding="5px"
            >
              <tr v-if="network_type===2">
                <td
                  class="td-left"
                  style="width:60%"
                >
                  TrafficManager subdomain
                </td><td>{{ subdomain }}</td>
              </tr>
              <tr v-if="network_type===1">
                <td class="td-left">
                  Domain
                </td><td>{{ domain }}</td>
              </tr>
              <tr v-if="network_type===1">
                <td class="td-left">
                  DNS
                </td><td>{{ dns_type_option[dns_type-1].text }}</td>
              </tr>
              <tr>
                <td class="td-left">
                  Datacenter location
                </td><td>{{ dataCenterOption[dataCenter-1].text }}</td>
              </tr>
            </table>
            <br>
            <table
              v-if="network_type===1"
              class="settingTable"
              cellpadding="5px"
            >
              <tr>
                <td
                  colspan="2"
                  class="td-title"
                >
                  Subdomain
                </td>
              </tr>
              <tr>
                <td class="td-left">
                  Admin/Control Panel
                </td><td>{{ admin.subdomain }}</td>
              </tr>
              <tr>
                <td class="td-left">
                  Offers tracking domain
                </td><td>{{ offers.subdomain }}</td>
              </tr>
              <tr>
                <td class="td-left">
                  Smart tracking domain
                </td><td>{{ smart.subdomain }}</td>
              </tr>
              <tr>
                <td class="td-left">
                  Banners
                </td><td>{{ banners.subdomain }}</td>
              </tr>
              <tr>
                <td class="td-left">
                  Static
                </td><td>{{ static_domain.subdomain }}</td>
              </tr>
              <tr>
                <td class="td-left">
                  Postback
                </td><td>{{ postback.subdomain }}</td>
              </tr>
            </table>
            <div>
              <b-alert
                show
                dismissible
                variant="warning"
              >
                Once you confirm your details, you will not be able to change them later.
              </b-alert>
            </div>

            <b-button
              style="margin:5px 0px 0px 0px"
              variant="primary"
              @click="confirm_save"
            >Confirm</b-button>
            <b-button
              style="margin:5px 0px 0px 3px"
              variant="primary"
              @click="cancel_save"
            >Back</b-button>

          </div>
        </b-modal>

        <!-- <b-modal
          id="save-dpa-agreement"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          size="xl"
          title="Please accept the DPA Agreement."
        >
          <div class="text-center">
            <DpaAgreementVue
              :customer="customer"
              @generatedPDF="checkGeneratedPDF"
              @userAgreeToDPA="setDPAAgreement"
            />
            <b-button
              style="margin:5px 0px 0px 0px"
              variant="primary"
              @click="confirm_save_dpa"
            >Confirm</b-button>
            <b-button
              style="margin:5px 0px 0px 3px"
              variant="primary"
              @click="cancel_save_dpa"
            >Close</b-button>
          </div>
        </b-modal> -->

      </b-col>
    </b-row>

  </div>
</template>

<script>
/* eslint-disable */
import {
  BRow, BCol, BCard, BCardText, BFormSelect, BBadge, BCardHeader,
  BFormGroup, BFormInput, BFormRadioGroup, BCardBody, BInputGroupText,
  BInputGroup, BSpinner, BButton, BModal, BAlert,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'
import ConfigSubdomain from './ConfigSubdomain.vue'
import ConfigShortSubdomain from './ConfigShortSubdomain.vue'
// import DpaAgreementVue from '../user_dpa_agreements/UserDpaAgreement.vue'
import axios from '@/libs/axios' // eslint-disable-line

import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BCardBody,
    BBadge,
    BCardHeader,
    BInputGroup,
    BInputGroupText,
    ConfigSubdomain,
    ConfigShortSubdomain,
    BSpinner,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BAlert,
    // DpaAgreementVue
  },
  data() {
    return {
      sending: false,

      // data center location
      dataCenterOption: [
        { value: 1, text: 'Europe' },
        { value: 2, text: 'America (US West Coast)' },
        { value: 3, text: 'America (US East Coast)' },
        { value: 4, text: 'Asia' },
      ],
      dataCenter: 1,

      password: '',
      password_confirmation: '',

      // network domain
      network_type: 1,
      network_type_option: [
        { value: 1, text: 'Your own domain' },
        //{ value: 2, text: 'TrafficManager subdomain' },
      ],
      domain: '',

      dns_type: 1,
      dns_type_option: [
        { value: 1, text: 'TrafficManager DNS (Recommended)' },
        { value: 2, text: 'Your own DNS' },
      ],

      subdomain: '',
      subdomain_backup: '',
      admin: {
        title: 'DASHBOARD / CONTROL PANEL',
        domain: 'mydomain.com',
        subdomain: 'dashboard',
        description: 'This is the domain used by the control panel, where all your affiliates, advertisers, affiliate managers, admins etc. will log in and manage their account.',
        useTMdomain: false,
        validated: false,
      },
      offers: {
        title: 'OFFERS TRACKING DOMAIN',
        domain: 'mydomain.com',
        subdomain: 'offers',
        description: 'The default subdomain to which the affiliates send traffic to, that redirects the user to the offer landing pages. More tracking domains can be added later.',
        useTMdomain: false,
        validated: false,
      },
      smart: {
        title: 'SMART TRACKING DOMAIN',
        domain: 'mydomain.com',
        subdomain: 'smart',
        description: 'Similar to offers tracking domain, but used for the "smart tools" (smartlinks, banners, pop-ups, etc.)',
        useTMdomain: false,
        validated: false,
      },
      banners: {
        title: 'BANNERS TRACKING DOMAIN',
        domain: 'mydomain.com',
        subdomain: 'banners',
        description: 'The subdomain where the static creatives are served from (mainly images or video banners)',
        useTMdomain: false,
        validated: false,
      },
      static_domain: {
        title: 'STATIC',
        domain: 'mydomain.com',
        subdomain: 'static',
        description: 'The subdomain for serving static assets required by the control panel (images, CSS and JavaScript files)',
        useTMdomain: false,
        validated: false,
      },
      postback: {
        title: 'POSTBACK',
        domain: 'mydomain.com',
        subdomain: 'postback',
        description: 'The subdomain that will receive the server-to-server Postback requests for offers and campaigns',
        useTMdomain: false,
        validated: false,
      },
      // short: {
      //   title: 'SHORT DOMAIN (optional)',
      //   domain: '',
      //   placeholder: 'short.ly',
      //   description: 'A short domain to enable the "shortlink" feature.This domain must be set as a CNAME of the offers domain.Leave this field empty if you don\'t want to enable this feature.',
      // },

      customer: {
        uid: 0,
        name: 'Customer Name',
        company: 'Customer Company',
        title: 'Customer Title',
        position: "Customer Position",
        address: "Customer Address",
        signature: '',
        hasSigned: false,
        dateSigned: '',
      },
      agreeDPA: false,
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.getCookie("videoConfig") != 'showed') this.$bvModal.show('video-config')
    }, 1000);
  },
  methods: {
    showVideoConfig() {
      this.$bvModal.show('video-config')
    },
    closeVideoConfig() {
      this.$bvModal.hide('video-config')
      this.setCookie("videoConfig", 'showed', 100);
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      let expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    changeNetworkType() {
      const networkType = Number(this.network_type)

      if (networkType === 1) {
        this.subdomain_backup = this.subdomain
        this.subdomain = ''
        this.assignOwnDomain()
      } else if (networkType === 2) {
        this.assignTMDomain()
        if (this.subdomain_backup) this.subdomain = this.subdomain_backup
      }
    },
    changeDomain() {
      this.domain = this.domain.toLowerCase()
      this.assignOwnDomain()
    },
    assignOwnDomain() {
      let assignDomain = '.mynetwork.com'
      if (this.domain) assignDomain = this.domain
      this.assignDomain(assignDomain, this.subdomain, false)
    },

    assignTMDomain() {
      const assignDomain = '.trafficmanager.com'
      let { subdomain } = this
      this.subdomain = subdomain.toLowerCase()
      if (this.subdomain_backup) subdomain = this.subdomain_backup
      else if (!this.subdomain) subdomain = 'mysubdomain'
      this.assignDomain(assignDomain, subdomain, true)
    },

    assignDomain(assignDomain, subdomain, isTMdomain) {
      const domain = assignDomain
      const admin_subdomain = 'dashboard'
      const offer_subdomain = 'offers'
      const smart_subdomain = 'smart'
      const banner_subdomain = 'banners'
      const static_subdomain = 'static'
      const postback_subdomain = 'postback'

      this.admin.domain = domain
      this.admin.useTMdomain = isTMdomain
      if (!isTMdomain) this.admin.subdomain = admin_subdomain
      if (subdomain) this.admin.subdomain = subdomain

      this.offers.domain = domain
      this.offers.useTMdomain = isTMdomain
      this.offers.subdomain = offer_subdomain
      if (subdomain) this.offers.subdomain = `${offer_subdomain}.${subdomain}`

      this.smart.domain = domain
      this.smart.useTMdomain = isTMdomain
      this.smart.subdomain = smart_subdomain
      if (subdomain) this.smart.subdomain = `${smart_subdomain}.${subdomain}`

      this.banners.domain = domain
      this.banners.useTMdomain = isTMdomain
      this.banners.subdomain = banner_subdomain
      if (subdomain) this.banners.subdomain = `${banner_subdomain}-${subdomain}`

      this.static_domain.domain = domain
      this.static_domain.useTMdomain = isTMdomain
      this.static_domain.subdomain = static_subdomain
      if (subdomain) this.static_domain.subdomain = `${static_subdomain}-${subdomain}`

      this.postback.domain = domain
      this.postback.useTMdomain = isTMdomain
      this.postback.subdomain = postback_subdomain
      if (subdomain) this.postback.subdomain = `${postback_subdomain}.${subdomain}`
    },

    async confirm_save() {
      this.$bvModal.hide('save-config')
      // this.$bvModal.hide('save-dpa-agreement')
      this.sending = true
      const network_type = this.network_type == 1 ? 'domain' : 'subdomain' // eslint-disable-line
      const dns_type = this.dns_type == 1 ? 'tmdns' : 'owndns' // eslint-disable-line

      const payload = {
        network_type,
        dns_type,
        domain: this.domain.toLowerCase(),
        subdomain: this.subdomain.toLowerCase(),
        datacenter_location: this.dataCenter,
        admin_password_1: this.password,
        admin_password_2: this.password_confirmation,
        sub_admin: this.admin.subdomain,
        sub_offers: this.offers.subdomain,
        sub_smart: this.smart.subdomain,
        sub_banners: this.banners.subdomain,
        sub_static: this.static_domain.subdomain,
        sub_postback: this.postback.subdomain,
        short_domain: '',
        // short_domain: this.short.domain,
        action_type: 'setting',
      }

      try {
        const response = await axios.post('/api/settings/save_configuration', payload)

        if(response?.data?.error) {
          this.sending = false;
          let msg = '';
          if (Array.isArray(response.data[0])) {
            msg = response.data[0][0];
          } else {
            const key = Object.keys(response.data[0])[0];
            msg = response.data[0][key][0];
          }

          this.notify({
            text: msg,
            variant: 'danger',
          });
          document.getElementById("domain").focus();
        }
        else {
          if (this.network_type === 2) {
            VueCookies.set('isNewSubDomain', 'yes', '1d')
          }

          this.notify({
            text: 'Configuration is successful.',
            variant: 'success',
          })
          setTimeout(() => {
            window.location.reload()
          }, '2000')
        }
      } catch (error) {
        console.error(error);
      }
    },

    cancel_save() {
      this.$bvModal.hide('save-config')
      // if(!this.customer.hasSigned) this.$bvModal.show('save-dpa-agreement')
    },

    // confirm_save_dpa(){
      // if(this.agreeDPA) {
      //   this.$bvModal.show('save-config')

      //   const dpaModal = document.getElementById('save-dpa-agreement');
      //   dpaModal.style.visibility = "hidden";
      //   dpaModal.style.opacity = "0";
      //   document.body.style.overflow = "visible";

      //   const dpaBackdrop = document.getElementById('save-dpa-agreement___BV_modal_backdrop_');
      //   dpaBackdrop.remove();

      //   this.customer.hasSigned = true;
      // } else {
      //   this.notify({
      //     text: 'Please make sure you sign the DPA and fill out the form completely. Thank you!',
      //     variant: 'danger',
      //   })
      // }
    // },

    // cancel_save_dpa() {
    //   this.$bvModal.hide('save-config')

    //   if(!this.agreeDPA) return this.$bvModal.hide('save-dpa-agreement')

    //   const dpaModal = document.getElementById('save-dpa-agreement');
    //   dpaModal.style.visibility = "hidden";
    //   dpaModal.style.opacity = "0";
    //   document.body.style.overflow = "visible";

    //   const dpaBackdrop = document.getElementById('save-dpa-agreement___BV_modal_backdrop_');
    //   dpaBackdrop.remove();
    // },

    // checkGeneratedPDF(value) {
    //   if (value) this.agreeDPA = true;
    //   else {
    //     this.$bvToast.toast('The PDF could not be generated successfully! Please create a ticket !', {
    //       title: 'PDF Generated',
    //       variant: 'danger',
    //       solid: true,
    //       autoHideDelay: 10000,
    //     });
    //   }
    // },

    // setDPAAgreement(value) {
    //   this.agreeDPA = value;
    // },

    async save() {
      await this.$refs.admin_subdomain.validate()
      await this.$refs.offers_subdomain.validate()
      await this.$refs.smart_subdomain.validate()
      await this.$refs.banners_subdomain.validate()
      await this.$refs.static_subdomain.validate()
      await this.$refs.website_subdomain.validate()

      const validated = []
      const admin = { admin: this.admin.validated }
      const offers = { offers: this.offers.validated }
      const smart = { smart: this.smart.validated }
      const banners = { banners: this.banners.validated }
      const static_domain = { static_domain: this.static_domain.validated }
      const postback = { postback: this.postback.validated }

      validated.push(admin)
      validated.push(offers)
      validated.push(postback)
      validated.push(smart)
      validated.push(banners)
      validated.push(static_domain)

      const isPassed = await this.$refs.rules.validate().then(success => success)
      const main = { main: isPassed }
      validated.push(main)

      let isFinalValidationOk = true
      validated.forEach(obj => {
        const val = Object.values(obj)[0]
        if (val === false) isFinalValidationOk = false
      })

      if (isFinalValidationOk === true) {
        this.$bvModal.show('save-config')
        // if(this.agreeDPA) this.$bvModal.show('save-config')
        // else this.$bvModal.show('save-dpa-agreement')

      } else {
        this.notify({
          text: 'Please check the required field to continue.',
          variant: 'danger',
        })
        this.sending = false
      }
    },

    // async fillCustomerInfo()
    // {
    //   const checkEmptyData = (data) => {
    //       return (data?.length < 2 || !data || data == " ") ? ('N/A') : (data);
    //   };

    //   const user = this.$store.state.auth.userData;
    //   this.customer.uid = user.uid;

    //   const response = await axios.get(`/api/dpa/get_last_signed_dpa/${user.uid}`);

    //   if (response.data.signed === true) {
    //     const info = response.data.info;
    //     this.customer.name = info.full_name;
    //     this.customer.company = info.company;
    //     this.customer.title = info.title;
    //     this.customer.address = info.address;
    //     this.customer.signature = info.signature;
    //     this.customer.dateSigned = info.updated_at;
    //     this.customer.hasSigned = true;
    //     this.agreeDPA = true;
    //   } else {
    //     this.customer.name = `${user.forename} ${user.surname}`;
    //     this.customer.company = checkEmptyData(user.company);
    //     this.customer.title = checkEmptyData(user.title);
    //     this.customer.address  =  `${user.address1} ${user.city}, ${user.region} ${user.country}`;
    //     this.customer.hasSigned = false;
    //     this.agreeDPA = false;
    //   }

    //   if(this.customer.company != user.company) {
    //     this.customer.hasSigned = false;
    //     this.agreeDPA = false;
    //   }
    // },
  },
}
</script>

<style lang="scss">

.input-domain {
    direction: rtl; // or dir attribute is rtl in input tag
    text-align: start;
}

.ch-title {
  position: relative;
  top: -2px;
}

.warning-section {
  background: #f44336;
  color: #fff;
}
.modal .modal-header .close{
  margin: 2px
}
.settingTable{
  width:100%;
}
.settingTable td{
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
}
.td-left{
  width: 40%;
  font-weight: bolder;
}
.td-title{
  font-weight: bolder;
}
.alert{
  padding:5px !important
}
.mobileContainer{
  min-width: 340px;
}
.mobileWidth{
  min-width: 330px;
}
@media only screen and (max-width: 600px) {
   .mbwindow{
    padding-left: 0px !important;
  }
  .passmargin{
    margin-top: 10px;
  }
 }
</style>
