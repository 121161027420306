<template>
  <div>
    <br>
    <h6 class="font-weight-bolder mb-1 text-danger"><feather-icon
      icon="LinkIcon"
      size="16"
      class="ch-title"
    /> {{ website.title }}
    </h6>
    <b-form-input
      v-model="website.domain"
      :placeholder="website.placeholder"
    />
    <p style="color:#8c8c8c; font-size:small;text-align: justify;">
      {{ website.description }}
    </p>
  </div>
</template>

<script>
import {
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
  },
  props: {
    website: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>

.ch-title {
  position: relative;
  top: -2px;
}

</style>
